import 'swiper/swiper.min.css'

import React, { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Box } from '@rebass/grid'
import Copy from './Copy'
import { Keyboard } from 'swiper'
import Pagination from './Pagination'
import Slide from './Slide'
import styled from 'styled-components'

const Coverflow = ({ items }) => {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.activeIndex)
  }

  const goToSlide = (index) => {
    if (swiper) {
      swiper.slideTo(index)
    }
  }

  return (
    <Wrapper>
      <Pagination
        swiper={swiper}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      <Copy currentIndex={currentIndex}>
        Our philosophy, practice, and <br /> process
      </Copy>
      <Swiper
        onSwiper={setSwiper}
        slidesPerView={'auto'}
        centeredSlides={true}
        onSlideChange={handleSlideChange}
        keyboard={{
          enabled: true,
        }}
        modules={[Keyboard]}
        speed={800}
      >
        {items?.map((data, index) => (
          <SwiperSlide
            key={`coverflow-slide-${index}`}
            onClick={() => goToSlide(index)}
          >
            <Slide data={data} currentIndex={currentIndex} index={index} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  )
}

export default Coverflow

const Wrapper = styled(Box)`
  padding-top: 40px;
  padding-bottom: 40px;

  position: relative;
  width: 100%;
  height: auto;
  background-color: #202020;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: 640px;
    width: 360px;
    box-sizing: content-box !important;

    display: flex;
    background: #eee;
    align-items: center;
    justify-content: center;
    transition: all 250ms ease-in-out;

    transform: scale(0.7) !important;
  }

  .swiper-slide-next,
  .swiper-slide-prev {
    transform: scale(0.8) !important;
  }

  .swiper-slide-active {
    transform: scale(1) !important;
  }

  @media (max-width: 800px) {
    .swiper-slide {
      height: auto;
      width: 100%;
      transform: scale(1) !important;
    }
    padding-top: 0px;
    padding-bottom: 0px;
  }
`
